import firebase from './firebase';
import firestore from 'firebase';
import {
  ICrossdock,
  IDeliveryAttempt,
  IDeliveryServicePartner,
  ILabelV2,
  IPickupLocation,
  IScanEvent,
  ISMSLog,
  ITrackingEvent,
  IUserBase,
  TrackingEventState,
} from '@swyft/swyft-common';
import { IRoutingInstance, IRoutingPlan } from '@swyft/swyft-common/lib/routing/types';
import { Query, FirestoreError } from '@firebase/firestore-types';
import { userConverter } from './types';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

const db = firebase.firestore();

/** Uncomment to use local emulated db */
// db.useEmulator('localhost', 8080);

interface Observer {
  next?: (snapshot: any) => void;
  error?: (error: FirestoreError) => void;
  complete?: () => void;
}

/**
 * Returns a query for the merchants collection.
 * @returns firestore query
 */
const getMerchantsCollection = () => {
  return db.collection('merchants');
};

/**
 * Returns a query for all labelsV2 collection.
 * @returns firestore query
 */
const getLabelsV2CollectionGroup = () => {
  return db.collectionGroup('labelsV2');
};

/**
 * Returns a query for all tracking-event collection group.
 * @returns firestore query
 */
const getTrackingEventsCollectionGroup = () => {
  return db.collectionGroup('tracking-events');
};

/**
 * Returns a query for the crossdock collection
 * @returns firestore query
 */
const getCrossdocksCollection = () => {
  return db.collection('crossdocks');
};

/**
 * Returns a query for the Onfleet task creation batches collection
 * @returns firestore query
 */
const getOnfleetTaskBatchesCollection = (): Query => {
  return db.collection('onfleet-task-creation-batches');
};

const getRoutingInstancesCollection = () => {
  return db.collection('routing-instances');
};

const getDeliveryServicePartnersCollection = () => {
  return db.collection('delivery-service-partners');
};

/**
 * Returns a query for labels given a ship date
 * @param shipDate formatted string ship date (for ex. '2020-12-12')
 * @returns firestore query
 */
const getLabelsForShipDateQuery = (shipDate: string): Query => {
  return getLabelsV2CollectionGroup()
    .where('shipDate', '==', shipDate)
    .where('state', '!=', TrackingEventState.DELETED);
};

/**
 * Fetches a user from firestore given an email
 * @param email string
 * @returns IUser
 */
export const getUserByEmail = async (email: string) => {
  return db.collection('users').where('email', '==', email).limit(1).get();
};

/**
 * Fetches a user from firestore by uid
 * @param uid string
 * @returns IUser
 */
export const getUser = async (uid: string): Promise<IUserBase> => {
  const getUserReq = await db
    .collection('users')
    .withConverter(userConverter)
    .where('id', '==', uid)
    .limit(1)
    .get();
  const user = getUserReq.docs.map((doc) => doc.data())[0];

  return user;
};

const getRoutingInstanceQuery = (crossdockId: string, shipDate: string) => {
  return getRoutingInstancesCollection()
    .where('crossdockId', '==', crossdockId)
    .where('shipDate', '==', shipDate)
    .orderBy('createdAt', 'desc');
};

/**
 * Fetches labels for given ship date and merchant slugs. Returns all labels for ship date if no merchant slug is provided.
 * @param shipDate formatted string ship date (for ex. '2020-12-13')
 * @param merchantSlugs array of merchant slugs to check for, passing empty array returns all labels for ship date
 * @param crossdockId Optional string, if specified, only returns labels associated to that crossdock
 * @returns promise for array of label data
 */
export const getLabelsForShipDateAndSlugs = async (
  shipDate: string,
  merchantSlugs: string[],
  crossdockId?: string
): Promise<ILabelV2[]> => {
  let labelsForShipDateQuery = getLabelsForShipDateQuery(shipDate);
  if (Array.isArray(merchantSlugs) && merchantSlugs.length > 0) {
    labelsForShipDateQuery = labelsForShipDateQuery.where('merchantSlug', 'in', merchantSlugs);
  }
  if (crossdockId) {
    labelsForShipDateQuery = labelsForShipDateQuery.where(
      'associatedCrossdockId',
      '==',
      crossdockId
    );
  }

  const queryResult = await labelsForShipDateQuery.get();

  return queryResult.docs.map((doc) => doc.data() as ILabelV2);
};

/**
 * Fetches labels for given ship date range and merchant slugs. Returns all labels for ship date if no merchant slug is provided.
 * @param startDate formatted string start ship date (for ex. '2020-12-13')
 * @param endDate formatted string end ship date (for ex. '2020-12-13')
 * @param merchantSlugs array of merchant slugs to check for, passing empty array returns all labels for ship date
 * @param crossdockId Optional string, if specified, only returns labels associated to that crossdock
 * @param dspId Optional string, if specified, only returns labels associated to that DSP
 * @returns promise for array of label data
 */
export const getLabelsForShipDateRangeAndSlugs = async (
  startDate: string,
  endDate: string,
  merchantSlugs: string[],
  crossdockId?: string,
  dspId?: string
): Promise<ILabelV2[]> => {
  let labelsForShipDateQuery = getLabelsV2CollectionGroup()
    .where('shipDate', '>=', startDate)
    .where('shipDate', '<=', endDate);

  if (Array.isArray(merchantSlugs) && merchantSlugs.length > 0) {
    labelsForShipDateQuery = labelsForShipDateQuery.where('merchantSlug', 'in', merchantSlugs);
  }

  if (crossdockId) {
    labelsForShipDateQuery = labelsForShipDateQuery.where(
      'associatedCrossdockId',
      '==',
      crossdockId
    );
  }

  if (dspId) {
    labelsForShipDateQuery = labelsForShipDateQuery.where('dspId', '==', dspId);
  }

  const queryResult = await labelsForShipDateQuery.get();

  return queryResult.docs.map((doc) => doc.data() as ILabelV2);
};


/**
 * Fetches labels for given date range, selected statuses and merchant slugs. Returns all labels with selected statuses for date if no merchant slug is provided.
 * @param startDate formatted string start tracking-event date (for ex. '2020-12-13')
 * @param endDate formatted string end tracking-event date (for ex. '2020-12-13')
 * @param merchantSlugs array of merchant slugs to check for, passing empty array returns all labels for selected date
 * @param trackingStatuses array of tracking-event statuses (up to 10), returns all labels for date with this statuses
 * @param crossdockId Optional string, if specified, only returns labels associated to that crossdock
 * @param dspId Optional string, if specified, only returns labels associated to that DSP
 * @returns promise for array of label data
 */
export const getLabelsForTrackingEventStatuses = async (
  startDate: string,
  endDate: string,
  timezone: string,
  merchantSlugs: string[],
  trackingStatuses: string[],
  crossdockId?: string,
  dspId?: string
): Promise<ILabelV2[]> => {

  const startDateSpecificTimezone = momentTimezone.tz(startDate, timezone).toDate();
  const endDateSpecificTimezone = momentTimezone.tz(endDate, timezone).endOf('day').toDate();

  const startDateTimestamp = firestore.firestore.Timestamp.fromDate(
    startDateSpecificTimezone
  );
  const endDateTimestamp = firestore.firestore.Timestamp.fromDate(
    endDateSpecificTimezone
  );

  // console.log('startDateTimestamp', startDateSpecificTimezone);
  // console.log('endDateTimestamp', endDateSpecificTimezone);

  const trackingEventsForLabelsQuery = await getTrackingEventsCollectionGroup()
    .where('state', 'in', trackingStatuses)
    .where('timestamp', '>=', startDateTimestamp)
    .where('timestamp', '<', endDateTimestamp)
    .get();

  const labelsV2Refs = new Set<string>();

  trackingEventsForLabelsQuery.forEach((doc) => {
    const labelsV2Ref = doc.ref.parent.parent;
    if (labelsV2Ref) {
      labelsV2Refs.add(labelsV2Ref.path);
    }
  });

  const labelsV2Ids = Array.from(labelsV2Refs);

  if (!labelsV2Ids.length) {
    return [] as ILabelV2[];
  }
  const queries = await getFilteredLabels(labelsV2Ids, merchantSlugs, crossdockId, dspId);

  const snapshots = await Promise.all(queries.map((query) => query.get()));
  const labels: ILabelV2[] = [];

  snapshots.forEach((snapshot) => {
    snapshot.forEach((doc) => {
      const data = doc.data() as ILabelV2;
      labels.push(data);
    });
  });

  return labels;
};

/**
 * Fetches a label by its ID
 *
 * @param labelId the id to lookup by
 */
export const getLabelById = async (labelId: string): Promise<ILabelV2> => {
  const result = await getLabelsV2CollectionGroup().where('id', '==', labelId).get();

  if (result.docs.length === 0) {
    return Promise.reject('No label found for the given id.');
  }

  if (result.docs.length > 1) {
    return Promise.reject(
      `Unexpected number of labels found for the given id. Found label count: ${result.docs.length}`
    );
  }

  return result.docs[0].data() as ILabelV2;
};

/**
 * Fetches labels in list
 *
 * @param labelIds the id list to lookup by
 */
export const getLabelsByList = async (labelIds: string[]): Promise<ILabelV2[]> => {
  const result = await Promise.all(labelIds.map((labelId) => getLabelById(labelId)));

  if (result.length != labelIds.length) {
    return Promise.reject(
      `Unexpected number of labels found for the given list. Found label count: ${result.length}`
    );
  }

  return result;
};

/**
 * Fetches labels for given ship date and crossdock id.
 * @param shipDate formatted string ship date (for ex. '2020-12-13')
 * @param crossdockId crossdock id to check for
 * @returns promise for an array of labels
 */
export const getLabelsForShipDateAndCrossdockId = async (
  shipDate: string,
  crossdockId: string
): Promise<ILabelV2[]> => {
  const queryResult = await getLabelsForShipDateQuery(shipDate)
    .where('associatedCrossdockId', '==', crossdockId)
    .get();
  return queryResult.docs.map((doc) => doc.data() as ILabelV2);
};

/**
 * Fetches all existing (not deleted) merchant slugs from the database.
 * @returns promise for array of merchant slugs
 */
export const getAllMerchantSlugs = async (): Promise<string[]> => {
  const queryResult = await getMerchantsCollection().get();

  return queryResult.docs
    .map((doc) => doc.data())
    .filter((merchant) => !merchant.deletedAt)
    .map(({ slug }) => slug);
};

/**
 * Fetches all crossdocks from the database
 * @return Promise resolving into an array of crossdocks
 */
export const getCrossdock = async (id: string): Promise<ICrossdock> => {
  const query = getCrossdocksCollection().doc(id);
  const result = await query.get();
  return result.data() as ICrossdock;
};

/**
 * Fetches all crossdocks from the database
 * @return Promise resolving into an array of crossdocks
 */
export const getAllCrossdocks = async (): Promise<ICrossdock[]> => {
  const query = getCrossdocksCollection();
  const result = await query.get();
  return result.docs
    .map((doc) => doc.data() as ICrossdock)
    .filter((crossdock: ICrossdock) => !crossdock.deletedAt); // Filters deleted crossdocks
};

/**
 * Fetches all DSPs from the database
 * @return Promise resolving into an array of crossdocks
 */
export const getAllDeliveryServicePartners = async (): Promise<IDeliveryServicePartner[]> => {
  const query = getDeliveryServicePartnersCollection();
  const result = await query.get();
  return result.docs.map((doc) => doc.data() as IDeliveryServicePartner);
};

export const getSmsLogs = async (merchantId: string, labelId: string): Promise<ISMSLog[]> => {
  const queryResult = await getMerchantsCollection()
    .doc(merchantId)
    .collection('labelsV2')
    .doc(labelId)
    .collection('SMS-logs')
    .get();

  return queryResult.docs.map((doc) => doc.data() as ISMSLog);
};

export const getTrackingEvents = async (
  merchantId: string,
  labelId: string
): Promise<ITrackingEvent[]> => {
  const queryResult = await getMerchantsCollection()
    .doc(merchantId)
    .collection('labelsV2')
    .doc(labelId)
    .collection('tracking-events')
    .get();

  return queryResult.docs.map((doc) => doc.data() as ITrackingEvent);
};

export const getDeliveryAttempts = async (
  merchantId: string,
  labelId: string,
  orderBy?: 'desc' | 'asc'
): Promise<IDeliveryAttempt[]> => {
  const queryResult = await getMerchantsCollection()
    .doc(merchantId)
    .collection('labelsV2')
    .doc(labelId)
    .collection('delivery-attempts')
    .orderBy('createdAt', orderBy || 'desc')
    .get();

  return queryResult.docs.map((doc) => doc.data() as IDeliveryAttempt);
};

export const getScanEvents = async (merchantId: string, labelId: string): Promise<IScanEvent[]> => {
  const queryResult = await getMerchantsCollection()
    .doc(merchantId)
    .collection('labelsV2')
    .doc(labelId)
    .collection('scan-events')
    .get();

  return queryResult.docs.map((doc) => doc.data() as IScanEvent);
};

export const getPickupLocationById = async (pickupLocationId: string): Promise<IPickupLocation> => {
  const queryResult = await db
    .collectionGroup('pickup-locations')
    .where('id', '==', pickupLocationId)
    .get();

  return queryResult.docs[0].data() as IPickupLocation;
};

export const getRoutingInstances = async (
  crossdockId: string,
  shipDate: string
): Promise<IRoutingInstance[]> => {
  const queryResult = await getRoutingInstanceQuery(crossdockId, shipDate).get();
  return queryResult.docs.map((doc) => doc.data() as IRoutingInstance);
};

export const streamRoutingInstances = (
  crossdockId: string,
  shipDate: string,
  observer: Observer
): (() => void) => {
  return getRoutingInstanceQuery(crossdockId, shipDate).onSnapshot(observer);
};

export const getRoutingPlansForInstance = async (
  routingInstanceId: string
): Promise<IRoutingPlan[]> => {
  const queryResult = await getRoutingInstancesCollection()
    .doc(routingInstanceId)
    .collection('routing-plans')
    .get();

  return queryResult.docs.map((doc) => doc.data() as IRoutingPlan);
};

const getFilteredLabels = async (
  labelsV2Ids: string[],
  merchantSlugs: string[],
  crossdockId?: string,
  dspId?: string
) => {
  const queries: firestore.firestore.Query<firestore.firestore.DocumentData>[] = [];

  while (labelsV2Ids.length) {
    const batch = labelsV2Ids.splice(0, 10);
    let baseQuery = getLabelsV2CollectionGroup().where(
      firestore.firestore.FieldPath.documentId(),
      'in',
      batch
    );

    if (crossdockId) {
      baseQuery = baseQuery.where('associatedCrossdockId', '==', crossdockId);
    }

    if (dspId) {
      baseQuery = baseQuery.where('dspId', '==', dspId);
    }

    if (merchantSlugs.length > 0) {
      merchantSlugs.forEach((merchantSlug) => {
        let query = baseQuery.where('merchantSlug', '==', merchantSlug);
        queries.push(query);
      });
    } else {
      queries.push(baseQuery);
    }
  }

  return queries.flat();
};
